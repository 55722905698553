import { useState, useEffect } from 'react';
import {providers } from "ethers";
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

import "./ConnectWallet.css";

function ConnectWallet({ setUiConnected,setAccountConnected,accountConnected}) {
    const [web3Modal, setWeb3Modal] = useState(null);
    
    const network ={
      bsc: {
        chainId: `0x${Number(56).toString(16)}`,
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
          name: "Binance Chain Native Token",
          symbol: "BNB",
          decimals: 18},
          rpcUrls:[
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed2.binance.org",
            "https://bsc-dataseed3.binance.org",
            "https://bsc-dataseed4.binance.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "wss://bsc-ws-node.nariox.org"
            ],
          blockExplorerUrls:["https://bscscan.com"],
        },

      }

    useEffect(() => {
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: process.env.REACT_APP_INFURA_KEY,
            }
          },
        };
    
        const newWeb3Modal = new Web3Modal({
          cacheProvider: false, 
          network: "mainnet",
          // providerOptions,
        });
    
        setWeb3Modal(newWeb3Modal)
        
    }, [])

    useEffect(() => {
        if(web3Modal 
           && web3Modal.cachedProvider
           ){
          connectWallet()
          }
        }, [web3Modal])


  

    async function connectWallet() {
      if(window.ethereum.chainId!==network.bsc.chainId){
        try{await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params:[{chainId:network.bsc.chainId}]

        })}
        catch(switchError){
          if(switchError.code===4902){
            try{
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [network.bsc]});
              } catch(addChainerr){
                console.log(addChainerr)
              }
          }else{
            console.log(switchError)
          }
        }
      }
      const provider = await web3Modal.connect();
      addListeners(provider);
      const ethersProvider = new providers.Web3Provider(provider)
      const userAddress = await ethersProvider.getSigner().getAddress()
      setUiConnected(true)
      setAccountConnected(window.ethereum.selectedAddress)
      localStorage.setItem('uiConnected',JSON.stringify(true))
    }

    async function disconnectWallet(){
        await web3Modal.clearCachedProvider();      
        setUiConnected(null)
        localStorage.removeItem('uiConnected')
       
    }

    async function addListeners(web3ModalProvider) {
        
        web3ModalProvider.on("accountsChanged", () => {
          setAccountConnected(window.ethereum.selectedAddress)
          console.log(`account changed! ${window.ethereum.selectedAddress}`)          
          if(accountConnected===null){
            localStorage.removeItem('uiConnected')
          }
          window.location.reload()
        });
        
        // Subscribe to chainId change
        web3ModalProvider.on("chainChanged", (chainId) => {
          window.location.reload()
        });
        
    }

    return (
      <>
        { localStorage.getItem('uiConnected') ? (
              <>
                  {/* <p className='text-white'>{address}</p> */}
                  <button type="button" className="btn btn-primary" onClick={disconnectWallet}>DISCONNECT WALLET</button>
              </>
              
          ): (
              <button type="button" className="btn btn-primary" onClick={connectWallet}>CONNECT WALLET</button>
          )}
      </>
    );
}

export default ConnectWallet;
